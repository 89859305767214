export const MENUITEMS = [

  {
    menutitle: 'Pages',
    menucontent: 'Ready to use Apps',
    Items: [
      { path: `${process.env.PUBLIC_URL}/dashboard/default`, icon: 'home',title: 'Dashboard', type: 'link' },
      { path: `${process.env.PUBLIC_URL}/app/campaign`, icon: 'file', title: 'Campaign', type: 'link' },
      { path: `${process.env.PUBLIC_URL}/app/file-manager`, icon: 'file', title: 'Company', type: 'link' },
      { path: `${process.env.PUBLIC_URL}/app/email-app`, icon: 'user', title: 'People', type: 'link' },
      { path: `${process.env.PUBLIC_URL}/app/faq`, icon: 'bookmark', type: 'link', active: false, title: 'Keywords' },
    ],
  },
];
