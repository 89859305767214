import React, { useState } from "react";
import { Suspense } from "react";
import { BrowserRouter, Route, Routes, Navigate } from "react-router-dom";
import Loader from "../Layout/Loader";
import LayoutRoutes from "../Route/LayoutRoutes";
import { classes } from "../Data/Layouts";
import Default from '../Components/Dashboard/Default';
import SignIn from "../Components/Login/signIn";


const Routers = () => {
  const defaultLayoutObj = classes.find(
    (item) => Object.values(item).pop(1) === "compact-wrapper"
  );
  const layout =
    localStorage.getItem("layout") || Object.keys(defaultLayoutObj).pop();

  const [isAuthenticated, setIsAuthenticated] = useState(false);

  const handleLogin = () => {
    // perform login authentication
    setIsAuthenticated(true);
  }

  return (
    <BrowserRouter basename={"/"}>
      <Suspense fallback={<Loader />}>
        <Routes>
          <Route exact path={`${process.env.PUBLIC_URL}/login`} element={<SignIn onLogin={handleLogin} />} />
          <Route exact path={`/`} element={<Navigate to={`${process.env.PUBLIC_URL}/login`} />} />
          <Route path={`/dashboard/default/${layout}`} element={ isAuthenticated ? <Default /> : <Navigate to={`${process.env.PUBLIC_URL}/login`} /> } />
          <Route path={`/*`} element={<LayoutRoutes />} />
        </Routes>
      </Suspense>
    </BrowserRouter>
  );
};

export default Routers;




// import React from "react";
// import { Suspense } from "react";
// import { BrowserRouter, Route, Routes, Navigate } from "react-router-dom";
// import Loader from "../Layout/Loader";
// import LayoutRoutes from "../Route/LayoutRoutes";
// import { classes } from "../Data/Layouts";
// // import SignIn from "../Components/Login/signIn";

// const Routers = () => {
//   const defaultLayoutObj = classes.find(
//     (item) => Object.values(item).pop(1) === "compact-wrapper"
//   );
//   const layout =
//     localStorage.getItem("layout") || Object.keys(defaultLayoutObj).pop();

//   return (
//     <BrowserRouter basename={"/"}>
//       <Suspense fallback={<Loader />}>
//         <Routes>
//           <Route
//             exact
//             path={`${process.env.PUBLIC_URL}/login`}
//             element={
//               <Navigate
//                 to={`${process.env.PUBLIC_URL}/login`}
//               />
//             }
//           />
//           <Route
//             exact
//             path={`/`}
//             element={
//               <Navigate
//                 to={`${process.env.PUBLIC_URL}/login`}
//               />
//             }
//           />

//           <Route path={`/*`} element={<LayoutRoutes />} />
//           {/* </Route> */}
//         </Routes>
//       </Suspense>
//     </BrowserRouter>
//   );
// };

// export default Routers;

// import React, { useState } from "react";
// import { Suspense } from "react";
// import { BrowserRouter, Route, Routes, Navigate, useNavigate } from "react-router-dom";
// import Loader from "../Layout/Loader";
// import LayoutRoutes from "../Route/LayoutRoutes";
// import { classes } from "../Data/Layouts";
// import SignIn from "../Components/Login/signIn";

// const Routers = () => {
//   const defaultLayoutObj = classes.find(
//     (item) => Object.values(item).pop(1) === "compact-wrapper"
//   );
//   const layout =
//     localStorage.getItem("layout") || Object.keys(defaultLayoutObj).pop();

//   const [isAuthenticated, setIsAuthenticated] = useState(false);
//   const navigate = useNavigate();

//   const handleSignIn = () => {
//     setIsAuthenticated(true);
//     navigate(`/dashboard/default/${layout}`);
//   };

//   return (
//     <BrowserRouter basename={"/"}>
//       <Suspense fallback={<Loader />}>
//         <Routes>
//           <Route
//             exact
//             path={`${process.env.PUBLIC_URL}`}
//             element={
//               isAuthenticated ? (
//                 <Navigate to={`/dashboard/default/${layout}`} />
//               ) : (
//                 <SignIn onSignIn={handleSignIn} />
//               )
//             }
//           />
//           <Route
//             exact
//             path={`/`}
//             element={
//               isAuthenticated ? (
//                 <Navigate to={`/dashboard/default/${layout}`} />
//               ) : (
//                 <SignIn onSignIn={handleSignIn} />
//               )
//             }
//           />

//           <Route path={`/*`} element={<LayoutRoutes />} />
//           {/* </Route> */}
//         </Routes>
//       </Suspense>
//     </BrowserRouter>
//   );
// };

// export default Routers;
