// dashbaord
import Default from '../Components/Dashboard/Default';

// // Buttons
import DefaultButton from '../Components/Buttons/Default';
import FlatButton from '../Components/Buttons/FlatBtn/index';
import EdgeButton from '../Components/Buttons/Edge/index';
import RaisedButton from '../Components/Buttons/RaisedBtn/index';

// //Email
import MailInboxContain from '../Components/Application/Email/MailInbox';

// //File Manager
import FileManagerContain from '../Components/Application/FileManager';


import Keyword from '../Components/FAQ';
import SignIn from '../Components/Login/signIn';
import Campaign from '../Components/Application/Campaign';


export const routes = [
  //login
  {path: `${process.env.PUBLIC_URL}/login`,Component: <SignIn />},

  //dashboard
  { path: `${process.env.PUBLIC_URL}/dashboard/default/:layout`, Component: <Default /> },

  // // //Buttons
  { path: `${process.env.PUBLIC_URL}/buttons/simplebutton/:layout`, Component: <DefaultButton /> },
  { path: `${process.env.PUBLIC_URL}/buttons/flat/:layout`, Component: <FlatButton /> },
  { path: `${process.env.PUBLIC_URL}/buttons/edge/:layout`, Component: <EdgeButton /> },
  { path: `${process.env.PUBLIC_URL}/buttons/raised/:layout`, Component: <RaisedButton /> },


  { path: `${process.env.PUBLIC_URL}/app/email-app/:layout`, Component: <MailInboxContain /> },
  { path: `${process.env.PUBLIC_URL}/app/file-manager/:layout`, Component: <FileManagerContain /> },
  { path: `${process.env.PUBLIC_URL}/app/campaign/:layout`, Component: <Campaign /> },

  // //Faq
  { path: `${process.env.PUBLIC_URL}/app/faq/:layout`, Component: <Keyword /> },
];
