import { Btn, H5 } from '../../AbstractElements';
import React, { Fragment, useState, useEffect } from 'react';
import { Col, Card, CardHeader, CardBody, Label, Collapse } from 'reactstrap';
import { Select } from 'antd';

const { Option } = Select;

const Size = () => {
  const [isFilter, setIsFilter] = useState(true);
  const [sizes, setSizes] = useState([]);

  useEffect(() => {
    // Fetch the list of sizes from the API endpoint
    fetch('https://backend.leadgeneration.urbanoinfotech.com/companyfilter/')
      .then((response) => response.json())
      .then((data) => {
        // Assuming the "results" key contains the array of items
        const sizesData = data.results || [];
        setSizes(sizesData.map(item => item.size)); // Extracting only the "size" values
      })
      .catch((error) => {
        console.error('Error fetching sizes:', error);
      });
  }, []);

  return (
    <Fragment>
      <Col xl='12'>
        <Card>
          <CardHeader>
            <H5 attrH5={{ className: 'mb-0 p-0' }}>
              <Btn attrBtn={{ className: 'btn btn-link', onClick: () => setIsFilter(!isFilter), color: 'transparent' }}>Size</Btn>
            </H5>
          </CardHeader>
          <Collapse isOpen={isFilter}>
            <CardBody className='animate-chk'>
              <div className='size-dropdown'>
                <Label>Select Size:</Label>
                <Select
                  style={{ width: '100%' }}
                  placeholder="Select size"
                  onChange={(value) => {
                    // Handle size selection (if needed)
                  }}
                >
                  {sizes.map((size, index) => (
                    <Option key={index} value={size}>
                      {size}
                    </Option>
                  ))}
                </Select>
              </div>
            </CardBody>
            <Btn attrBtn={{ className: 'btn d-block w-100 text-center', color: 'primary', type: 'button' }}>Find Companies</Btn>
          </Collapse>
        </Card>
      </Col>
    </Fragment>
  );
};

export default Size;


