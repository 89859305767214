import React, { useState } from "react";
import MasterForm from "./form"; // Import your wizard form component

const CampaignBtn = () => {
  const [showModal, setShowModal] = useState(false);

  const handleButtonClick = () => {
    setShowModal(true);
  };

  const closeModal = () => {
    setShowModal(false);
  };

  return (
    <div>
      <button
        style={{
          backgroundColor: "#7366ff",
          color: "#fff",
          border: "none",
          borderRadius: "4px",
          padding: "10px 20px",
          cursor: "pointer",
          marginTop: "2rem",
        }}
        onClick={handleButtonClick}
      >
        Add Campaign
      </button>
      {showModal && (
        <div
          style={{
            position: "fixed",
            top: 0,
            left: 0,
            width: "100%",
            height: "100%",
            backgroundColor: "rgba(0, 0, 0, 0.5)",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <div
            style={{
              backgroundColor: "#fff",
              padding: "20px",
              borderRadius: "5px",
              boxShadow: "0 0 10px rgba(0, 0, 0, 0.3)",
              position: "relative",
              maxWidth: "600px",
              width: "80%",
              maxHeight: "80%",
              overflow: "auto",
            }}
          >
            <span
              style={{
                position: "absolute",
                top: "10px",
                right: "10px",
                cursor: "pointer",
                fontSize: "24px",
              }}
              onClick={closeModal}
            >
              &times;
            </span>
            <MasterForm />
          </div>
        </div>
      )}
    </div>
  );
};

export default CampaignBtn;


// import React, { useState } from "react";
// import MasterForm from "./form"; // Import your wizard form component

// const CampaignBtn = () => {
//   const [showModal, setShowModal] = useState(false);

//   const handleButtonClick = () => {
//     setShowModal(true);
//   };

//   const closeModal = () => {
//     setShowModal(false);
//   };

//   return (
//     <div>
//       <button style={{backgroundColor:"#7366ff",marginLeft:"90%",marginTop:"2%"}} onClick={handleButtonClick}>Add Campaign</button>
//       {showModal && (
//         <div
//           style={{
//             position: "fixed",
//             top: 0,
//             left: 0,
//             width: "100%",
//             height: "100%",
//             backgroundColor: "rgba(0, 0, 0, 0.5)",
//             display: "flex",
//             alignItems: "center",
//             justifyContent: "center",
//           }}
//         >
//           <div
//             style={{
//               backgroundColor: "#fff",
//               padding: "20px",
//               borderRadius: "5px",
//               boxShadow: "0 0 10px rgba(0, 0, 0, 0.3)",
//               position: "relative",
//             }}
//           >
//             <span
//               style={{
//                 position: "absolute",
//                 top: "10px",
//                 right: "10px",
//                 cursor: "pointer",
//                 fontSize: "24px",
//               }}
//               onClick={closeModal}
//             >
//               &times;
//             </span>
//             <MasterForm />
//           </div>
//         </div>
//       )}
//     </div>
//   );
// };

// export default CampaignBtn;
