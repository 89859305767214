import React, { Fragment, useState, useEffect } from "react";
import { Btn } from "../../AbstractElements";
import { ModalTitle, Simple } from "../../Constant";
import CommonModal from "./common/modal";
import { Form, FormGroup, Input, Label } from "reactstrap";
import Switch from "./switch";
import axios from "axios";

const BasicBtn = () => {
  const [modal, setModal] = useState(false);
  const toggle = () => setModal(!modal);
  const [switchOn, setSwitchOn] = useState(false);
  const [keyword, setKeyword] = useState("");
  const [searchType, setSearchType] = useState(null);
  const [location, setLocation] = useState("");
  const [schedule, setSchedule] = useState(false);
  const [scheduleDay, setScheduleDay] = useState(null);
  const [scheduleHour, setScheduleHour] = useState("");
  const [locationOptions, setLocationOptions] = useState([]);
  const [locationCode, setLocationCode] = useState("");

  useEffect(() => {
    fetchLocationOptions();
  }, []);

  const fetchLocationOptions = () => {
    axios
      .get("https://backend.leadgeneration.urbanoinfotech.com/location")
      .then((response) => {
        setLocationOptions(response.data.results);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const handleSwitchChange = () => {
    setSwitchOn(!switchOn);
    setSchedule(true);
  };

  const handleSave = () => {
    const selectedLocation = locationOptions.find((option) => option.Location === location);
    if (selectedLocation) {
      setLocationCode(selectedLocation.Location_Code);
    }
    const data = {
      keyword,
      search_type: searchType,
      location,
      schedule,
      schedule_day: scheduleDay,
      schedule_hour: scheduleHour,
    };
    axios
      .post(`${process.env.REACT_APP_API_URL}keyword/`, data)
      .then((response) => {
        console.log(response.data);
        toggle();
      })
      .catch((error) => {
        console.log(error);
      });
  };

  return (
    <Fragment>
      <div style={{ justifyContent: "flex-start", paddingBottom: "2rem", margin: "0" }}>
        <Btn attrBtn={{ color: "primary", onClick: toggle }}>Add Keywords</Btn>
      </div>
      <CommonModal isOpen={modal} title={ModalTitle} toggler={toggle}>
        <Form>
          <FormGroup>
            <Label for="exampleEmail">Keyword</Label>
            <Input
              type="text"
              name="keyword"
              id="exampleEmail"
              placeholder="Enter a keyword you want to search for..."
              value={keyword}
              onChange={(e) => setKeyword(e.target.value)}
            />
          </FormGroup>
          <FormGroup>
            <Label for="exampleSelect">Filters</Label>
            <Input
              type="select"
              name="select"
              id="exampleSelect"
              value={searchType}
              onChange={(e) => setSearchType(e.target.value)}
            >
              <option value={null}>Select a filter...</option>
              <option value="COMPANIES">COMPANIES</option>
              <option value="PEOPLE">PEOPLE</option>
            </Input>
          </FormGroup>
          <FormGroup>
            <Label for="exampleEmail">Location</Label>
            <Input
              type="select"
              name="location"
              id="exampleEmail"
              placeholder="Enter a location to search in..."
              value={location}
              onChange={(e) => setLocation(e.target.value)}
            >
              <option value="">Select a location...</option>
              {locationOptions.map((option) => (
                <option key={option.id} value={option.Location_Code}>
                  {option.Location}
                </option>
              ))}
            </Input>
          </FormGroup>
          <FormGroup>
            <Switch switchOn={switchOn} handleSwitchChange={handleSwitchChange} />
          </FormGroup>
          {switchOn ? (
            <FormGroup>
              <Label for="exampleDate">Schedule Day</Label>
              <Input
                type="date"
                name="date"
                id="exampleDate"
                placeholder="date placeholder"
                value={scheduleDay}
                onChange={(e) => setScheduleDay(e.target.value)}
              />
            </FormGroup>
          ) : null}
          {switchOn ? (
            <FormGroup>
              <Label for="exampleTime">Schedule Hour</Label>
              <Input
                type="time"
                name="schedule_hour"
                id="exampleTime"
                placeholder="time placeholder"
                value={scheduleHour}
                onChange={(e) => setScheduleHour(e.target.value)}
              />
            </FormGroup>
          ) : null}
        </Form>
        <Btn attrBtn={{ color: "primary", onClick: handleSave }}>Save</Btn>
      </CommonModal>
    </Fragment>
  );
};

export default BasicBtn;




// import React, { Fragment, useState, useEffect } from "react";
// import { Btn } from "../../AbstractElements";
// import { ModalTitle, Simple } from "../../Constant";
// import CommonModal from "./common/modal";
// import { Form, FormGroup, Input, Label } from "reactstrap";
// import Switch from "./switch";
// import axios from "axios";

// const BasicBtn = () => {
//   const [modal, setModal] = useState(false);
//   const toggle = () => setModal(!modal);
//   const [switchOn, setSwitchOn] = useState(false);
//   const [keyword, setKeyword] = useState("");
//   const [searchType, setSearchType] = useState(null);
//   const [location, setLocation] = useState("");
//   const [schedule, setSchedule] = useState(false);
//   const [scheduleDay, setScheduleDay] = useState(null);
//   const [scheduleHour, setScheduleHour] = useState("");
//   const [locationOptions, setLocationOptions] = useState([]);
//   const [location_code, setLocation_code] = useState("")

//   useEffect(() => {
//     fetchLocationOptions();
//   }, []);

//   const fetchLocationOptions = () => {
//     axios
//       .get("https://backend.leadgeneration.urbanoinfotech.com/location")
//       .then((response) => {
//         const filteredResults = response.data.results.filter((item) => item.Location !== null);
//         const options = filteredResults.map((item) => item.Location);
//         setLocationOptions(options);
//       })
//       .catch((error) => {
//         console.log(error);
//       });
//   };

//   const handleSwitchChange = () => {
//     setSwitchOn(!switchOn);
//     setSchedule(true);
//   };

//   const handleSave = () => {
//     const data = {
//       keyword,
//       search_type: searchType,
//       location,
//       schedule,
//       schedule_day: scheduleDay,
//       schedule_hour: scheduleHour,
//     };
//     axios
//       .post(`${process.env.REACT_APP_API_URL}keyword/`, data)
//       .then((response) => {
//         console.log(response.data);
//         toggle();
//       })
//       .catch((error) => {
//         console.log(error);
//       });
//   };

//   return (
//     <Fragment>
//       <div style={{ justifyContent: "flex-start", paddingBottom: "2rem", margin: "0" }}>
//         <Btn attrBtn={{ color: "primary", onClick: toggle }}>Add Keywords</Btn>
//       </div>
//       <CommonModal isOpen={modal} title={ModalTitle} toggler={toggle}>
//         <Form>
//           <FormGroup>
//             <Label for="exampleEmail">Keyword</Label>
//             <Input
//               type="text"
//               name="keyword"
//               id="exampleEmail"
//               placeholder="Enter a keyword you want to search for..."
//               value={keyword}
//               onChange={(e) => setKeyword(e.target.value)}
//             />
//           </FormGroup>
//           <FormGroup>
//             <Label for="exampleSelect">Filters</Label>
//             <Input
//               type="select"
//               name="select"
//               id="exampleSelect"
//               value={searchType}
//               onChange={(e) => setSearchType(e.target.value)}
//             >
//               <option value={null}>Select a filter...</option>
//               <option value="COMPANIES">COMPANIES</option>
//               <option value="PEOPLE">PEOPLE</option>
//             </Input>
//           </FormGroup>
//          <FormGroup>
//             <Label for="exampleEmail">Location</Label>
//             <select
//               className="form-control"
//               name="location"
//               id="exampleEmail"
//               value={location}
//               onChange={(e) => setLocation(e.target.value)}
//             >
//               <option value="">Select a location...</option>
//               {locationOptions.map((option, index) => (
//                 <option key={index} value={option}>
//                   {option}
//                 </option>
//               ))}
//             </select>
//           </FormGroup>
//           <FormGroup>
//             <Label for="exampleEmail">Location Code</Label>
//             <select
//               className="form-control"
//               name="location"
//               id="exampleEmail"
//               value={location_code}
//               onChange={(e) => setLocation_code(e.target.value)}
//             >
//               <option value="">Select a location...</option>
//               {locationOptions.map((option, index) => (
//                 <option key={index} value={option}>
//                   {option}
//                 </option>
//               ))}
//             </select>
//           </FormGroup>
//           <FormGroup>
//             <Switch switchOn={switchOn} handleSwitchChange={handleSwitchChange} />
//           </FormGroup>
//           {switchOn ? ( // conditionally render date time input if switchOn is true
//             <FormGroup>
//               <Label for="exampleDate">Schedule Day</Label>
//               <Input
//                 type="date"
//                 name="date"
//                 id="exampleDate"
//                 placeholder="date placeholder"
//                 value={scheduleDay}
//                 onChange={(e) => setScheduleDay(e.target.value)}
//               />
//             </FormGroup>
//           ) : null}
//           {switchOn ? ( // conditionally render date time input if switchOn is true
//             <FormGroup>
//               <Label for="exampleTime">Schedule Hour</Label>
//               <Input
//                 type="time"
//                 name="schedule_hour"
//                 id="exampleTime"
//                 placeholder="time placeholder"
//                 value={scheduleHour}
//                 onChange={(e) => setScheduleHour(e.target.value)}
//               />
//             </FormGroup>
//           ) : null}
//         </Form>
//         <Btn attrBtn={{ color: "primary", onClick: handleSave }}>Save</Btn>
//       </CommonModal>
//     </Fragment>
//   );
// };

// export default BasicBtn;






// import React, { Fragment, useState } from "react";
// import { Btn } from "../../AbstractElements";
// import { ModalTitle, Simple } from "../../Constant";
// import CommonModal from "./common/modal";
// import { Form, FormGroup, Input, Label } from "reactstrap";
// import Switch from "./switch";
// import axios from "axios";

// const BasicBtn = () => {
//   const [modal, setModal] = useState(false);
//   const toggle = () => setModal(!modal);
//   const [switchOn, setSwitchOn] = useState(false);
//   const [keyword, setKeyword] = useState("");
//   const [searchType, setSearchType] = useState(null);
//   const [location, setLocation] = useState("");
//   const [schedule, setSchedule] = useState(false);
//   const [scheduleDay, setScheduleDay] = useState(null);
//   const [scheduleHour, setScheduleHour] = useState("");

//   const handleSwitchChange = () => {
//     setSwitchOn(!switchOn);
//     setSchedule(true);
//   };

//   const handleSave = () => {
//     const data = {
//       keyword,
//       search_type: searchType,
//       location,
//       schedule,
//       schedule_day: scheduleDay,
//       schedule_hour: scheduleHour,
//     };
//     axios
//       .post(`${process.env.REACT_APP_API_URL}keyword/`, data)
//       .then((response) => {
//         console.log(response.data);
//         toggle();
//       })
//       .catch((error) => {
//         console.log(error);
//       });
//   };

//   return (
//     <Fragment>
//       <div style={{  justifyContent: "flex-start", paddingBottom:"2rem" ,margin:"0"}}>
//         <Btn attrBtn={{ color: "primary", onClick: toggle }}>Add Keywords</Btn>
//       </div>
//       <CommonModal isOpen={modal} title={ModalTitle} toggler={toggle}>
//         <Form>
//           <FormGroup>
//             <Label for="exampleEmail">Keyword</Label>
//             <Input
//               type="text"
//               name="keyword"
//               id="exampleEmail"
//               placeholder="Enter a keyword you want to search for..."
//               value={keyword}
//               onChange={(e) => setKeyword(e.target.value)}
//             />
//           </FormGroup>
//           <FormGroup>
//             <Label for="exampleSelect">Filters</Label>
//             <Input
//               type="select"
//               name="select"
//               id="exampleSelect"
//               value={searchType}
//               onChange={(e) => setSearchType(e.target.value)}
//             >
//               <option value={null}>Select a filter...</option>
//               <option value="COMPANIES">COMPANIES</option>
//               <option value="PEOPLE">PEOPLE</option>
//             </Input>
//           </FormGroup>
//           <FormGroup>
//             <Label for="exampleEmail">Location</Label>
//             <Input
//               type="text"
//               name="location"
//               id="exampleEmail"
//               placeholder="Enter a location to search in..."
//               value={location}
//               onChange={(e) => setLocation(e.target.value)}
//             />
//           </FormGroup>
//           <FormGroup>
//             <Switch
//               switchOn={switchOn}
//               handleSwitchChange={handleSwitchChange}
//             />
//           </FormGroup>
//           {switchOn ? ( // conditionally render date time input if switchOn is true
//             <FormGroup>
//               <Label for="exampleDate">Schedule Day</Label>
//               <Input
//                 type="date"
//                 name="date"
//                 id="exampleDate"
//                 placeholder="date placeholder"
//                 value={scheduleDay}
//                 onChange={(e) => setScheduleDay(e.target.value)}
//               />
//             </FormGroup>
//           ) : null}
//           {switchOn ? ( // conditionally render date time input if switchOn is true
//             <FormGroup>
//               <Label for="exampleTime">Schedule Hour</Label>
//               <Input
//                 type="time"
//                 name="schedule_hour"
//                 id="exampleTime"
//                 placeholder="time placeholder"
//                 value={scheduleHour}
//                 onChange={(e) => setScheduleHour(e.target.value)}
//               />
//             </FormGroup>
//           ) : null}
//         </Form>
//         <Btn attrBtn={{ color: "primary", onClick: handleSave }}>Save</Btn>
//       </CommonModal>
//     </Fragment>
//   );
// };

// export default BasicBtn;