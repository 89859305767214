import React, { Fragment } from "react";
import { Container } from "reactstrap";
import { Breadcrumbs } from "../../../../AbstractElements";
import DataTable from "../../../Table";
// import Email from './Email';
// import MailSidebar from './MailSidebar';

const MailInboxContain = () => {
  const apiUrl = `${process.env.REACT_APP_API_URL}people`;
  return (
    <Fragment>
      <Breadcrumbs mainTitle="Employee" parent="Apps" title="Employee" />
      <Container fluid={true}>
        <div className="email-wrap">
          <DataTable apiUrl={apiUrl} />
        </div>
      </Container>
    </Fragment>
  );
};
export default MailInboxContain;
