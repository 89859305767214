import { Btn, H5 } from '../../AbstractElements';
import React, { Fragment, useState, useEffect } from 'react';
import { Col, Card, CardHeader, CardBody, Collapse, Label } from 'reactstrap';
import { Select } from 'antd';

const { Option } = Select;

const Location = () => {
  const [isFilter, setIsFilter] = useState(true);
  const [locations, setLocations] = useState([]);

  useEffect(() => {
    // Fetch the list of locations from the API endpoint
    fetch('https://backend.leadgeneration.urbanoinfotech.com/location/')
      .then((response) => response.json())
      .then((data) => {
        // Assuming the "results" key contains the array of locations
        const locationsData = data.results || [];
        setLocations(locationsData.map(item => item.Location)); // Extracting only the "Location" values
      })
      .catch((error) => {
        console.error('Error fetching locations:', error);
      });
  }, []);

  const handleLocationChange = (selectedLocations) => {
    // Handle the selected locations here (if needed)
    console.log('Selected Locations:', selectedLocations);
  };

  return (
    <Fragment>
      <Col xl='12'>
        <Card>
          <CardHeader>
            <H5 attrH5={{ className: 'mb-0 p-0' }}>
              <Btn attrBtn={{ className: 'btn btn-link', onClick: () => setIsFilter(!isFilter), color: 'transparent' }}>Location</Btn>
            </H5>
          </CardHeader>
          <Collapse isOpen={isFilter}>
            <CardBody className='animate-chk'>
              <div className='location-dropdown'>
                <Label>Select Location:</Label>
                <Select
                  mode="multiple" // Set the mode to "multiple" for multi-select
                  style={{ width: '100%' }}
                  placeholder="Select location"
                  onChange={handleLocationChange} // Handle location selection
                >
                  {locations.map((location, index) => (
                    <Option key={index} value={location}>
                      {location}
                    </Option>
                  ))}
                </Select>
              </div>
            </CardBody>
            <Btn attrBtn={{ className: 'btn d-block w-100 text-center', color: 'primary', type: 'button' }}>Find Companies</Btn>
          </Collapse>
        </Card>
      </Col>
    </Fragment>
  );
};

export default Location;




