import React, { Fragment, useState } from "react";
import { Btn } from "../../AbstractElements";
import { ModalTitle } from "../../Constant";
import CommonModal from "./common/modal";
import { Form, FormGroup, Input, Label } from "reactstrap";
import axios from "axios";

const CompanyBtn = () => {
  const [modal, setModal] = useState(false);
  const toggle = () => setModal(!modal);
  const [company_name, setCompanyName] = useState("");
  const [company_short_desc, setComapanyShortDesc] = useState("");
  const [company_url, setCompanyUrl] = useState("");
  const [linkedin_url, setLinkedinUrl] = useState("");
  const [number_of_employees, setNumberofEmployees] = useState(null);
  const [company_size, setCompanySize] = useState("");
  const [headquater, setheadquater] = useState("");
  const [company_type, setCompanyType] = useState("");
  const [founded_year, setFoundedyear] = useState("");
  const [specialities, setSpecialities] = useState("");
  const [industries, setIndustries] = useState("");
//   const [complete, setComplete] = useState("false");

  const handleSave = () => {
    const data = {
      company_name,
      company_short_desc,
      company_url,
      linkedin_url,
      number_of_employees,
      company_size,
      headquater,
      company_type,
      founded_year,
      specialities,
      industries,
    //   complete,
    };
    axios
      .post(`${process.env.REACT_APP_API_URL}company/`, data)
      .then((response) => {
        console.log(response.data);
        toggle();
      })
      .catch((error) => {
        console.log(error);
      });
  };

  return (
    <Fragment>
      <div
        style={{
          display: "flex",
          justifyContent: "flex-start",
          paddingBottom: "2rem",
        }}
      >
        <Btn attrBtn={{ color: "primary", onClick: toggle }}>Add Company</Btn>
      </div>
      <CommonModal isOpen={modal} title={ModalTitle} toggler={toggle}>
        <Form>
          <FormGroup>
            <Label for="exampleEmail">Comapny Name</Label>
            <Input
              type="text"
              name="keyword"
              id="exampleEmail"
              placeholder="Enter the name of Company..."
              value={company_name}
              onChange={(e) => setCompanyName(e.target.value)}
            />
          </FormGroup>
          <FormGroup>
            <Label for="exampleEmail">Comapny Short Desc</Label>
            <Input
              type="text"
              name="keyword"
              id="exampleEmail"
              placeholder="Description"
              value={company_short_desc}
              onChange={(e) => setComapanyShortDesc(e.target.value)}
            />
          </FormGroup>
          <FormGroup>
            <Label for="exampleEmail">Comapny Url</Label>
            <Input
              type="text"
              name="keyword"
              id="exampleEmail"
              placeholder="Company Website"
              value={company_url}
              onChange={(e) => setCompanyUrl(e.target.value)}
            />
          </FormGroup>
          <FormGroup>
            <Label for="exampleEmail">Linkedin Url</Label>
            <Input
              type="text"
              name="keyword"
              id="exampleEmail"
              placeholder="Enter the LinkedIn url of Company"
              value={linkedin_url}
              onChange={(e) => setLinkedinUrl(e.target.value)}
            />
          </FormGroup>
          <FormGroup>
            <Label for="exampleEmail">Number Of Employees</Label>
            <Input
              type="text"
              name="keyword"
              id="exampleEmail"
              placeholder="Enter the name of Company..."
              value={number_of_employees}
              onChange={(e) => setNumberofEmployees(e.target.value)}
            />
          </FormGroup>
          <FormGroup>
            <Label for="exampleEmail">Company Size</Label>
            <Input
              type="text"
              name="keyword"
              id="exampleEmail"
              placeholder="Size of the Company"
              value={company_size}
              onChange={(e) => setCompanySize(e.target.value)}
            />
          </FormGroup>
          <FormGroup>
            <Label for="exampleEmail">headquater</Label>
            <Input
              type="text"
              name="keyword"
              id="exampleEmail"
              placeholder="headquater"
              value={headquater}
              onChange={(e) => setheadquater(e.target.value)}
            />
          </FormGroup>
          <FormGroup>
            <Label for="exampleEmail">Comapny Type</Label>
            <Input
              type="text"
              name="keyword"
              id="exampleEmail"
              placeholder="Company type"
              value={company_type}
              onChange={(e) => setCompanyType(e.target.value)}
            />
          </FormGroup>
          <FormGroup>
            <Label for="exampleEmail">Founded Year</Label>
            <Input
              type="text"
              name="keyword"
              id="exampleEmail"
              placeholder="Enter the year in which company founded"
              value={founded_year}
              onChange={(e) => setFoundedyear(e.target.value)}
            />
          </FormGroup>
          <FormGroup>
            <Label for="exampleEmail">Specialities</Label>
            <Input
              type="text"
              name="keyword"
              id="exampleEmail"
              placeholder="..."
              value={specialities}
              onChange={(e) => setSpecialities(e.target.value)}
            />
          </FormGroup>
          <FormGroup>
            <Label for="exampleEmail">Industries</Label>
            <Input
              type="text"
              name="keyword"
              id="exampleEmail"
              placeholder="..."
              value={industries}
              onChange={(e) => setIndustries(e.target.value)}
            />
          </FormGroup>
          
        </Form>
        <Btn attrBtn={{ color: "primary", onClick: handleSave }}>Save</Btn>
      </CommonModal>
    </Fragment>
  );
};

export default CompanyBtn;
