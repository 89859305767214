import React, { Fragment } from "react";
import { CardBody, Col, Input, Label, Media } from "reactstrap";

const Switch = ({ switchOn, handleSwitchChange }) => {
  return (
    <Fragment>
      <CardBody className="row switch-showcase height-equal">
        <Col sm="12">
          <Media key='3'>
            <Label className="col-form-label m-r-10">Schedule</Label>
            <Media body className='text-end icon-state switch-outline'>
              <Label className="switch">
                <Input type="checkbox" checked={switchOn} onChange={handleSwitchChange}/>
                <span className='switch-state bg-success'></span>
              </Label>
            </Media>
          </Media>
        </Col>
      </CardBody>
    </Fragment>
  );
};
export default Switch;
