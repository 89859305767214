import React, { Fragment, useState } from "react";
import { Breadcrumbs, H2 } from "../../../AbstractElements";
// import { Col, Container, Row } from 'reactstrap';
import DataTable from "../../Table";
import CompanyBtn from "../../Modal/company";
import Filter from "../../Filter";
import { Col, Container, Row } from "react-bootstrap";
import Filters from "../../Filter";
// import Filter from "../../Filter/location";

const FileManagerContain = () => {
  const apiUrl = `${process.env.REACT_APP_API_URL}company`;

  return (
    <Fragment>
      <Breadcrumbs parent="Apps" title="Company" mainTitle="Company" />

      

      <Container fluid>
        <Row>
        <Col>
        <CompanyBtn /></Col>
        <Col style={{fontSize:'40px'}}>Company Table</Col>
        </Row>
        <Row>
          <Col>
            <Filters />
          </Col>
          <Col xs={9}>
           
            <DataTable apiUrl={apiUrl} />
          </Col>
        </Row>
      </Container>
    </Fragment>
  );
};
export default FileManagerContain;
