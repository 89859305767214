import React, { Fragment } from "react";
import { Container, Row } from "reactstrap";
import { Breadcrumbs } from "../../../AbstractElements";
import GreetingCard from "./GreetingCard";

const Dashboard = () => {
  return (
    <Fragment>
      <Breadcrumbs mainTitle="Dashboard" parent="Dashboard" title="Dashboard" />
      <Container fluid={true}>
        <Row className="widget-grid">
          <GreetingCard />
        </Row>
        
      </Container>
    </Fragment>
  );
};

export default Dashboard;
