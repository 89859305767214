import React, { useState } from "react";

function MasterForm() {
  const [name, setName] = useState("");
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [question, setQuestion] = useState("");
  const [questions, setQuestions] = useState([]);

  const handleChange = (event) => {
    const { name, value } = event.target;
    if (name === "name") setName(value);
    else if (name === "startDate") setStartDate(value);
    else if (name === "endDate") setEndDate(value);
  };

  const handleQuestionChange = (event) => {
    setQuestion(event.target.value);
  };

  const addQuestion = () => {
    setQuestions([...questions, question]);
    setQuestion("");
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    alert(
      `Campaign Details: \n
        Name: ${name} \n
        Start Date: ${startDate} \n
        End Date: ${endDate} \n
        Questions: ${questions.join(", ")}`
    );
  };

  return (
    <React.Fragment>
      <h1>Add Campaign Details 🧙‍♂️</h1>
      <form onSubmit={handleSubmit}>
        <div className="form-group">
          <label htmlFor="name">Campaign Name</label>
          <input
            className="form-control"
            id="name"
            name="name"
            type="text"
            placeholder="Enter Campaign Name"
            value={name}
            onChange={handleChange}
          />
          <label htmlFor="startDate">Start Date</label>
          <input
            className="form-control"
            id="startDate"
            name="startDate"
            type="date"
            value={startDate}
            onChange={handleChange}
          />
          <label htmlFor="endDate">End Date</label>
          <input
            className="form-control"
            id="endDate"
            name="endDate"
            type="date"
            value={endDate}
            onChange={handleChange}
          />
        </div>
        <div>
          <label>Questions</label>
          <div>
            <input
              type="text"
              placeholder="Enter a question"
              value={question}
              onChange={handleQuestionChange}
            />
            <button onClick={addQuestion}>Add Question</button>
          </div>
          <div>
            {questions.map((question, index) => (
              <div key={index}>{question}</div>
            ))}
          </div>
        </div>
        <button className="btn btn-success btn-block" type="submit">
          Add Campaign
        </button>
      </form>
    </React.Fragment>
  );
}

export default MasterForm;

// import React,{useState} from "react";
// import ReactFlow, { addEdge } from "react-flow-renderer";
// import { Controls, MiniMap, ReactFlowProvider } from "react-flow-renderer";

// class MasterForm extends React.Component {
//   constructor(props) {
//     super(props);
//     this.state = {
//       currentStep: 1,
//       name: "",
//       startDate: "",
//       endDate: "",
//       question: "",
//       questions: [],
//     };
//   }

//   handleChange = (event) => {
//     const { name, value } = event.target;
//     this.setState({
//       [name]: value,
//     });
//   };

//   handleSubmit = (event) => {
//     event.preventDefault();
//     const { name, startDate, endDate, questions } = this.state;
//     alert(
//       `Campaign Details: \n
//         Name: ${name} \n
//         Start Date: ${startDate} \n
//         End Date: ${endDate} \n
//         Questions: ${questions.join(", ")}`
//     );
//   };
//   handleQuestionChange = (event) => {
//     this.setState({
//       question: event.target.value,
//     });
//   };

//   addQuestion = () => {
//     const { question, questions } = this.state;
//     this.setState({
//       question: "",
//       questions: [...questions, question],
//     });
//   };

//   _next = () => {
//     let currentStep = this.state.currentStep;
//     currentStep = currentStep >= 3 ? 3 : currentStep + 1;
//     this.setState({
//       currentStep: currentStep,
//     });
//   };

//   _prev = () => {
//     let currentStep = this.state.currentStep;
//     currentStep = currentStep <= 1 ? 1 : currentStep - 1;
//     this.setState({
//       currentStep: currentStep,
//     });
//   };

//   /*
//    * the functions for our button
//    */
//   previousButton() {
//     let currentStep = this.state.currentStep;
//     if (currentStep !== 1) {
//       return (
//         <button
        
//           className="btn btn-secondary"
//           type="button"
//           onClick={this._prev}
//         >
//           Previous
//         </button>
//       );
//     }
//     return null;
//   }

//   nextButton() {
//     let currentStep = this.state.currentStep;
//     if (currentStep < 3) {
//       return (
//         <button
       
//           className="btn btn-primary float-right"
//           type="button"
//           onClick={this._next}
//         >
//           Next
//         </button>
//       );
//     }
//     return null;
//   }

//   render() {
//     return (
//       <React.Fragment>
//         <h1>Add Campaign Details 🧙‍♂️</h1>
//         <p>Step {this.state.currentStep}</p>

//         <form onSubmit={this.handleSubmit}>
//           {/* 
//         render the form steps and pass required props in
//       */}
//           <Step1
//             currentStep={this.state.currentStep}
//             handleChange={this.handleChange}
//             name={this.state.name}
//           />
//           <Step2 currentStep={this.state.currentStep} />
//           <Step3
//             currentStep={this.state.currentStep}
//             handleChange={this.handleChange}
//             questions={this.state.questions}
//           />
//           {this.previousButton()}
//           {this.nextButton()}
//         </form>
//       </React.Fragment>
//     );
//   }
// }

// function Step1(props) {
//   if (props.currentStep !== 1) {
//     return null;
//   }
//   return (
//     <div className="form-group">
//       <label htmlFor="name">Campaign Name</label>
//       <input
//         className="form-control"
//         id="name"
//         name="name"
//         type="text"
//         placeholder="Enter Campaign Name"
//         value={props.name}
//         onChange={props.handleChange}
//       />
//       <label htmlFor="startDate">Start Date</label>
//       <input
//         className="form-control"
//         id="startDate"
//         name="startDate"
//         type="date"
//         value={props.startDate}
//         onChange={props.handleChange}
//       />
//       <label htmlFor="endDate">End Date</label>
//       <input
//         className="form-control"
//         id="endDate"
//         name="endDate"
//         type="date"
//         value={props.endDate}
//         onChange={props.handleChange}
//       />
//       <label htmlFor="type">Type</label>
//       <select
//         className="form-control"
//         id="type"
//         name="type"
//         value={props.type}
//         onChange={props.handleChange}
//       >
//         <option value="">Select the type of Campaign</option>
//         <option value="Option 1">Option 1</option>
//         <option value="Option 2">Option 2</option>
//         <option value="Option 3">Option 3</option>
//         {/* Add more options as needed */}
//       </select>
//       <label htmlFor="type">Employee</label>
//       <select
//         className="form-control"
//         id="type"
//         name="type"
//         value={props.type}
//         onChange={props.handleChange}
//       >
//         <option value="">Name of person-in charge</option>
//         <option value="Option 1">Shubham Sharma</option>
//         <option value="Option 2">Asim Kumar Jha</option>
//         <option value="Option 3">Vishnu Yadav</option>
//         <option value="Option 3">Shahdev Thapa</option>
//         <option value="Option 3">Nirnay Shreshware</option>
//         <option value="Option 3">Sajid</option>
//         <option value="Option 3">Lovneet</option>
//         {/* Add more options as needed */}
//       </select>
//     </div>
//   );
// }
// function Step2(props) {
//   const [elements, setElements] = useState([
//     { id: "1", type: "input", data: { label: "Node 1" }, position: { x: 250, y: 5 } },
//     { id: "2", data: { label: <div>Node 2</div> }, position: { x: 100, y: 100 } },
//     { id: "e1-2", source: "1", target: "2", animated: true },
//   ]);

//   const onElementsChange = (changedElements) => {
//     setElements(changedElements);
//   };

//   const onConnect = (params) => {
//     const newElements = addEdge(params, elements);
//     setElements(newElements);
//   };

//   if (props.currentStep !== 2) {
//     return null;
//   }

//   return (
//     <div style={{ height: "400px", border: "1px solid #ccc" }}>
//       <ReactFlowProvider>
//         <ReactFlow
//           elements={elements}
//           onConnect={onConnect}
//           style={{ width: "100%", height: "100%" }}
//           snapToGrid={true}
//           snapGrid={[15, 15]}
//           zoomOnScroll={false}
//           minZoom={0.4}
//           maxZoom={1.5}
//         >
//           <MiniMap
//             nodeStrokeColor={(n) => {
//               if (n.style?.background) return n.style.background;
//               if (n.type === "input") return "#0041d0";
//               if (n.type === "output") return "#ff0072";
//               if (n.type === "default") return "#1a192b";
//               return "#eee";
//             }}
//             nodeColor={(n) => {
//               if (n.style?.background) return n.style.background;
//               return "#fff";
//             }}
//             nodeBorderRadius={2}
//           />
//           <Controls />
//         </ReactFlow>
//       </ReactFlowProvider>
//     </div>
//   );
// };

// function Step3(props) {
//   if (props.currentStep !== 3) {
//     return null;
//   }

//   return (
//     <div>
//       <label>Questions</label>
//       <div>
//         <input
//           type="text"
//           placeholder="Enter a question"
//           value={props.question}
//           onChange={props.handleQuestionChange}
//         />
//         <button onClick={props.addQuestion}>Add Question</button>
//       </div>
//       <div>
//         {props.questions.map((question, index) => (
//           <div key={index}>{question}</div>
//         ))}
//       </div>
//       <button className="btn btn-success btn-block">Add Campaign</button>
//     </div>
//   );
// }

// export default MasterForm;
