import React, { Fragment, useState } from "react";
import axios from "axios";
import { Btn, H4, P } from "../../AbstractElements";
import { Form, FormGroup, Input, Label } from "reactstrap";
import { Navigate } from "react-router-dom";
import backgroundImage from "./urbano_img.jpg";
import { Row, Col,Container } from "react-bootstrap";
import sideImage from "./2.jpg"

function SignIn({ onSignIn }) {
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState("");
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [togglePassword, setTogglePassword] = useState(false);

  const handleSubmit = async (event) => {
    event.preventDefault();
    try {
      // make a POST request to your sign-in API endpoint
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}api/token/`,
        { username, password }
      );

      if (response.status === 200) {
        // handle successful sign-in
        setIsAuthenticated(true);
        onSignIn();
      } else {
        setError("Sign-in failed. Please try again.");
      }
    } catch (error) {
      setError("Invalid username or password. Please try again.");
    }
  };

  

  return (
    <Fragment>
            <section style={{background:"aliceblue",backgroundSize:"cover"}}>
        <Container fluid={true}>
          <Row>
          <Col xl='7' className='b-center bg-size' style={{backgroundSize:"cover",backgroundPosition: 'center', display: 'block' }}>
            <img
              src={sideImage}
              alt="Background"
              className="login-image"
            />
          </Col>
          <Col  className='col-xl-5 p-0'>
          <Col style={{paddingLeft:"15%",paddingTop:"20%"}}>
            <img src={backgroundImage} alt="logo" />
          </Col>
          <Col style={{paddingLeft:"20%",paddingRight:"30%"}}>
            <div className="login-main" style={{boxShadow:"inherit"}}>
              <Form className="theme-form login-form" onSubmit={handleSubmit} >
                <H4>Sign in to account</H4>
                <P>Enter your email & password to login</P>
                {error && <p style={{ color: 'red' }}>{error}</p>} {/* Error message */}
                <FormGroup>
                  <Label className="col-form-label m-0">Email Address</Label>
                  <Input
                    className="form-control"
                    type="text"
                    required
                    placeholder="Test@gmail.com"
                    onChange={(event) => setUsername(event.target.value)}
                  />
                </FormGroup>
                <FormGroup className="position-relative">
                  <Label className="col-form-label m-0">Password</Label>
                  <div className="position-relative">
                    <Input
                      className="form-control"
                      name="login[password]"
                      type={togglePassword ? 'text' : 'password'}
                      required
                      placeholder="*********"
                      onChange={(event) => setPassword(event.target.value)}
                    />
                    <div
                      className="show-hide"
                      onClick={() => setTogglePassword(!togglePassword)}
                    >
                      <span className={togglePassword ? "" : "show"}></span>
                    </div>
                  </div>
                </FormGroup>
                <FormGroup>
                  <Btn
                    attrBtn={{
                      className: "d-block w-100 mt-2",
                      color: "primary",
                      type: "submit",
                    }}
                  >
                    Sign in
                  </Btn>
                  {isAuthenticated && (
                    <Navigate
                      to={`${process.env.PUBLIC_URL}/dashboard/default/:layout`}
                    />
                  )}
                </FormGroup>
              </Form>
            </div>
            </Col>
          </Col>
        </Row>
        </Container>
      </section>
    </Fragment>
  );
}

export default SignIn;

