import React, { Fragment, useState } from "react";
import { Col, Row } from "reactstrap";
import Filter from "./filter";
import { Btn } from "../../AbstractElements";
import Location from "./location";
import Size from "./size";

const Filters = () => {
  const [isOpen, setIsOpen] = useState(false);
  return (
    <Fragment>
      <Col className="box-col-4 xl-40">
        <div className="md-sidebar">
          <Btn
            attrBtn={{
              onClick: (e) => setIsOpen(!isOpen),
              color: "primary",
              className: "md-sidebar-toggle",
            }}
          >
            Job filter
          </Btn>
          <div
            className={`md-sidebar-aside job-left-aside custom-scrollbar ${
              isOpen && "open"
            } `}
          >
            <div
              className="default-according style-1 faq-accordion job-accordion"
              id="accordionoc"
            >
              <Row>
                <Filter />
                <Location />
                <Size />
              </Row>
            </div>
          </div>
        </div>
      </Col>
    </Fragment>
  );
};

export default Filters;
