import React, { Fragment } from "react";
import { Container, Row, Col } from "reactstrap";
import { Breadcrumbs, H2 } from "../../AbstractElements";
import DataTable from "../Table";
import BasicBtn from "../Modal/modal";
import LocationBtn from "../Modal/location_values";

const Keyword = () => {
  const apiUrl = `${process.env.REACT_APP_API_URL}keyword`;

  return (
    <Fragment>
      <Breadcrumbs mainTitle="Keywords" parent="Apps" title="Keywords" />
      <Container fluid={true}>

          <Row>
            <Col xs={1}>
              <BasicBtn />
            </Col>
            <Col xs={1}>
              <LocationBtn />
            </Col>
          </Row>
          <Row>
            <H2><center>Keywords Table</center></H2>
            <DataTable apiUrl={apiUrl} />
          </Row>

      </Container>
    </Fragment>
  );
};
export default Keyword;
