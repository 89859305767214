import React, { Fragment, useState } from "react";
import { Btn } from "../../AbstractElements";
import { ModalTitle } from "../../Constant";
import CommonModal from "./common/modal";
import { Form, FormGroup, Input, Label } from "reactstrap";
import axios from "axios";

const LocationBtn = () => {
  const [modal, setModal] = useState(false);
  const toggle = () => setModal(!modal);
  const [Location, setLocation] = useState("");
  const [Location_Code, setValue] = useState("");



  const handleSave = () => {
    const data = {
        Location,
        Location_Code

    };
    axios
      .post(`${process.env.REACT_APP_API_URL}location/`, data)
      .then((response) => {
        console.log(response.data);
        toggle();
      })
      .catch((error) => {
        console.log(error);
      });
  };

  
  return (
    <Fragment>
      <div style={{  justifyContent: "flex-start", paddingBottom:"2rem",margin:"0" }}>
        <Btn attrBtn={{ color: "primary", onClick: toggle }}>Add Location</Btn>
      </div>
      <CommonModal isOpen={modal} title={ModalTitle} toggler={toggle}>
        <Form>
          <FormGroup>
            <Label for="exampleEmail">Location</Label>
            <Input
              type="text"
              name="keyword"
              id="exampleEmail"
              placeholder="Enter The name of location..."
              value={Location}
              onChange={(e) => setLocation(e.target.value)}
            />
          </FormGroup>
          <FormGroup>
            <Label for="exampleEmail">Numeric Location Value</Label>
            <Input
              type="text"
              name="keyword"
              id="exampleEmail"
              placeholder="Enter The value of location..."
              value={Location_Code}
              onChange={(e) => setValue(e.target.value)}
            />
          </FormGroup>
        </Form>
        <Btn attrBtn={{ color: "primary", onClick: handleSave }}>Save</Btn>
      </CommonModal>
    </Fragment>
  );
};

export default LocationBtn;