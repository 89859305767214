import * as React from "react";
import {
  DataGrid,
  GridToolbarContainer,
  GridToolbarExport,
} from "@mui/x-data-grid";
import axios from "axios";

function CustomToolBar() {
  return (
    <GridToolbarContainer>
      <GridToolbarExport />
    </GridToolbarContainer>
  );
}

function DataTable({ apiUrl, filters }) {
  const [rows, setRows] = React.useState([]);
  const [columns, setColumns] = React.useState([]);
  const [page, setPage] = React.useState(1); // current page
  const [pageSize, setPageSize] = React.useState(100); // specify the desired page size
  const [totalCount, setTotalCount] = React.useState(0);

  React.useEffect(() => {
    let url = apiUrl;
    let params = {};

    // // Add filters to the URL params
    // if (filters && filters.India) {
    //   params["headquater"] = "India";
    // }
    // if (filters && filters.America) {
    //   params["headquater"] = "America";
    // }

    // // Add URL params to the URL
    // if (Object.keys(params).length > 0) {
    //   url += "?" + new URLSearchParams(params).toString();
    // }

    axios
      .get(url, { params: { count: true } }) // fetch the count of records
      .then((response) => {
        const totalCount = response.data.count;
        setTotalCount(totalCount);
        const newColumns = Object.keys(response.data.results[0]).map((key) => ({
          field: key,
          headerName: key.toUpperCase(),
          width: "300",
        }));
        setColumns(newColumns);
      })
      .catch((error) => {
        console.error(error);
      });
  }, [apiUrl, filters]);

  // Fetch the rows for the current page
  React.useEffect(() => {
    if (totalCount > 0) {
      const fetchData = async () => {
        const response = await axios.get(apiUrl, {
          params: { page, page_size: pageSize },
        });
        setRows(response.data.results);
      };
      fetchData();
    }
  }, [apiUrl, page, pageSize, totalCount]);

  const handlePageChange = (params) => {
    setPage(params.page);
  };

  return (
    <div style={{ height: "800px", width: "100%" }}>
      <DataGrid
        stickyHeader
        rows={rows}
        columns={columns}
        paginationMode="server"
        pagination
        onPageChange={handlePageChange}
        checkboxSelection
        pageSize={pageSize}
        rowCount={totalCount}
        slots={{
          toolbar: CustomToolBar,
        }}
      />
    </div>
  );
}

export default DataTable;
