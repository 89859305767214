import React from "react";
import CampaignBtn from "./modal";

const Campaign = () => {

    return(
        <div>
            <CampaignBtn />
        </div>
    )

}

export default Campaign;